/* PairNForComponent.css */

.pair-n-fork-container {
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .how-we-work-heading {
    font-size: 3rem;
    font-weight: bold;
    color: #ff469f; /* Pink color */
    margin-bottom: 20px;
  }
  
  .step {
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-bottom: 20px;
    width: 70%;
    transition: transform 0.3s;
  }
  
  .step:hover {
    transform: translateY(-5px);
  }
  
  .step h2 {
    color: #333;
    font-size: 1.5rem;
  }
  
  .step p {
    color: #333;
  }
  
  .why-choose h2,
  .join-us h2 {
    color: #333;
    margin-bottom: 15px;
  }
  
  .why-choose ul {
    list-style-type: none;
    padding: 0;
  }
  
  .why-choose li {
    margin-bottom: 10px;
  }
  
  .join-us p {
    color: #555;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 600px) {
    .step {
      padding: 15px;
    }
  
    .step h2 {
      font-size: 1.2rem;
    }
  
    .why-choose h2,
    .join-us h2 {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }
  
    .why-choose li {
      margin-bottom: 5px;
    }
  }
  