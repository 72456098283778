/* EventPage.css */

.full-page {
    background: linear-gradient(#eae1f3,#f7e0ee);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .event-page {
    width: 100%;
  }
  
  .event-content {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  
  .blind-dates {
    /* background-image: url('path/to/blind-dates-background.jpg'); Replace with the actual path */
  }
  
  h1, h2, p {
    margin: 10px 0;
  }
  
  .event-type {
    font-size: 1.2rem;
    color: #FF6EB5;
  }
  
  .event-description {
    color: #333;
  }
  
  .event-details {
    color: #555;
  }
  
  .book-event-btn {
    background-color: #FF6EB5;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
  }
  
  .book-event-btn:hover {
    background-color: #E649A5;
  }
  