.error-banner {
    background-color: #ff6666;
    color: #fff;
    padding: 15px;
    /* text-align: center; */
    display: flex;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .error-message {
    font-size: 18px;
    width: 90%;
    font-weight: bold;
  }

  .close-btnn {
    background-color: #fff;
    color: #ff6666;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
  }

  .close-btnn:hover {
    background-color: #ffdddd;
  }
