/* ProfilePage.css */


/*   
  .profile-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem 0;
    flex-direction: column;
  }
  
  .profile-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%; 
    padding: 20px;
    background-color: #c4b3e0;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .profile-info {
    display: flex;
    margin-top: 15px;
    width: 80%;
    flex-direction: column;
    text-align: left;
  }
  
  .profile-info p {
    margin: 5px 0;
    font-size: medium;
    font-weight: 400
    ;
  }
  
  h1 {
    color: #474747; 
    margin-bottom: 20px;
  }
  
  .profile-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .feature-button {
    padding: 10px;
    background-color: #6a5d8f; 
    color: #fff; 
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .feature-button:hover {
    background-color: #52466e; 
  } */
  
  /* Styling for the overall page or application can be added here */
  /* ProfileDetail.css */

.profile-container {
  
  min-width: 40%;
    max-width: 60%;
    width: 100%;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.profile-section {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

label {
  font-weight: bold;
  color: #333;
}

span {
  color: #666;
}
.profile-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

.feature-button {
  padding: 10px;
  background-color: #6a5d8f; 
  color: #fff; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.feature-button:hover {
  background-color: #52466e; 
}