/* RefundPolicy.css */

.refund-policy-container {
    max-width: 1000px;
    margin:  auto;
    padding: 20px;
    padding-top: 100px !important;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .refund-section {
    margin-top: 20px;
  }
  
  h2, h3 {
    color: #007bff;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  p {
    margin-top: 20px;
  }
  
