/* styles.css */

@import url('https://fonts.googleapis.com/css?family=Raleway');


.app {
  margin: auto;
}

.card-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
}

.snip1584 {
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 230px;
  max-width: 315px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  text-align: left;
  font-family: 'Monospace', sans-serif;
}

.snip1584 * {
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.snip1584:before {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  top: 100%;
  content: '';
  background-color: rgba(198, 180, 240, 0.9);
  transition: all 0.25s ease;
  transition-delay: 0.25s;
}

.snip1584 img {
  vertical-align: top;
  max-width: 100%;
  backface-visibility: hidden;
}

.snip1584 figcaption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.snip1584 h3,
.snip1584 h5 {
  margin: 0;
  opacity: 0;
  letter-spacing: 1px;
  max-width: 80%;
  word-wrap: break-word;
}

.snip1584 h3 {
  transform: translateY(-100%);
  text-transform: uppercase;
  font-weight: 400;
  transition-delay: 0.05s;
  margin-bottom: 5px;
}

.snip1584 h5 {
  font-weight: normal;
  background-color: #ae895d;
  padding: 3px 10px;
  transform: translateY(-100%);
  transition-delay: 0s;
  color: white;
}

.snip1584 a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.snip1584:hover:before,
.snip1584.hover:before {
  top: 10px;
  transition-delay: 0s;
}

.snip1584:hover h3,
.snip1584.hover h3,
.snip1584:hover h5,
.snip1584.hover h5 {
  transform: translateY(0);
  opacity: 1;
}

.snip1584:hover h3,
.snip1584.hover h3 {
  transition-delay: 0.3s;
}

.snip1584:hover h5,
.snip1584.hover h5 {
  transition-delay: 0.2s;
}

/* Additional styling for the news count */
.news__count {
  display: block;
  margin-top: 20px;
}

.none {
  display: none;
}
